const fieldLabel = {
  accessType: 'Access Type',
  addMSA: 'Add MSA',
  account: 'Account',
  accounts: 'Accounts',
  accountName: 'Account Name',
  acceptedOffer: 'Accepted Offer',
  accountStatus: 'Account Status',
  action: 'Action',
  actions: 'Actions',
  active: 'Active',
  activatedAt: 'Activated at',
  actualRetradeAmount: 'Actual Retrade Amount',
  addendumNotes: 'Addendum Notes',
  addendumNumber: 'Addendum Number',
  additionalAccessInformation: 'Additional Access Information',
  additionalTermsComments: 'Terms/Comments',
  address: 'Address',
  amount: 'Amount',
  assignedTo: 'Assigned To',
  knowledgeBaseCategory: 'Knowledge Base Category',
  parentKnowledgeBaseCategory: 'Parent Knowledge Base Category',
  bcc: 'BCC',
  brokerageLicense: 'Brokerage License',
  brokerRepAgentEmail: 'Broker/Rep Agent Email',
  brokerRepAgentLicenseNumber: 'Broker/Rep Agent License',
  emailCopied: 'Email Copied',
  brokerRepFirm: 'Broker/Rep Firm',
  brokerRepFirmLicenseNumber: 'Broker/Rep Firm License',
  brokerageRole: 'Brokerage Role',
  brokerageUser: 'Brokerage User',
  brokerageUsers: 'Brokerage Users',
  brokerRepMlsId: 'Broker/Rep MLSID',
  buyerContractName: 'Buyer Contract Name',
  buyerCounterofferReceiptDate: 'Buyer Counteroffer Receipt Date',
  buyerCounterofferReceivedFrom: 'Buyer Counteroffer Received From',
  buyerCounterofferResponseAmount: 'Buyer Counteroffer Response Amount',
  buyerNotes: 'Buyer Notes',
  buyerRequestedRetradeAmount: 'Buyer Requested Retrade Amount',
  cancelDate: 'Cancel Date',
  cancelNote: 'Cancel Notes',
  cancelReason: 'Cancellation Reason',
  cancelRequestReceivedDate: 'Cancel Request Received Date',
  cancelSubReason: 'Cancellation Reason Subcategory',
  cc: 'CC',
  changeLog: 'Change Log',
  changedStatus: 'Last Status',
  closeBalance: 'Close Balance',
  closeDate: 'Close Date',
  closings: 'Closings',
  closingToday: 'Closing Today',
  code: 'Code',
  create: 'Create',
  clearToClose: 'Clear To Close',
  counteredOffer: 'Countered Offer',
  commisionReduction: 'Commision Reduction',
  commisionReduction2: 'Commision Reduction 2',
  sqsStatusTrigger: 'SQS Status Trigger',
  company: 'Company',
  closedPurchased: 'Closed/Purchased',
  companyName: 'Company Name',
  companyPhone: 'Company Phone',
  competingOfferCount: 'Competing Offer Count',
  competingOfferHighestPrice: 'Competing Offer Highest Price',
  contactFirstName: 'Contact First Name',
  contactLastName: 'Contact Last Name',
  contractExecutionDate: 'Contract Execution Date',
  contractPrice: 'Contract Price',
  contractReceivedDate: 'Contract Received Date',
  contractTerminatedDate: 'Contract Terminated Date',
  contractTerminatedReasons: 'Contract Termination Reasons',
  counterOffer: 'Counter Offer',
  counterofferNotes: 'Counteroffer Notes',
  counterOfferPrice: 'Counter Offer Price',
  counterofferResponseTerms: 'Counteroffer Response Terms',
  createdDate: 'Created Date',
  crmStatus: 'CRM Status',
  customerType: 'Customer Type',
  date: 'Date',
  diligencePeriod: 'Diligence Period',
  dateEntered: 'Date Entered',
  dateModified: 'Date Modified',
  dateRange: 'Date Range',
  ddEnding: 'DD Ending',
  ddEndDate: 'DD End Date',
  ddFeePaymentTrackingNumber: 'DD Fee Payment Tracking Number',
  deactivatedAt: 'Deactivated At',
  depositAmount: 'Deposit Amount',
  description: 'Description',
  documentName: 'Document Name',
  documentState: 'Document State',
  documentSubType: 'Document Sub Type',
  extensionNumber: 'Extension Number',
  documentType: 'Document Type',
  dueDiligenceDaysConfirm: 'Due Diligence Days Confirmed?',
  dueDiligenceDate: 'Due Diligence Date',
  dueDiligenceEnd: 'Due Diligence End',
  dueDiligenceEndDate: 'Due Diligence End Date',
  dueDiligenceStartDate: 'Due Diligence Start Date',
  duplicate: 'Duplicate',
  earnestAmountStatus: 'Earnest Amount Status',
  earnestAmount: 'Earnest Amount',
  earnestMoney: 'Earnest Money',
  earnestAmountVerified: 'Earnest Amount Verified',
  earnestFee: 'Earnest Fee',
  earnestMoneyDueDate: 'Earnest Money Due Date',
  earnestStatus: 'Earnest Status',
  electricProvider: 'Electric Provider',
  email: 'Email',
  emails: 'Emails',
  emailInspection: 'Inspection',
  emdDeliveryData: 'EMD Delivery Date',
  emdRequestedConfirmed: 'EMD Requested Confirmed',
  emPaidByEntera: 'EM Paid by Entera',
  emPaidByEnteraAmount: 'EM Paid by Entera Amount',
  emRecoveredByBuyer: 'EM Recovered by Buyer',
  emRecoveryAmount: 'EM Recovery Amount',
  enableCallFromCts: 'Enable Call from CTS',
  enableCgmMultipleGenerateContract:
    'Enable Multiple Generate Contract from CGM',
  enableCgmPreviewContract: 'Enable Preview contract from CGM',
  enableGenerateCgmContract: 'Enable Generate contract from CGM',
  enableInboundEmail: 'Enable Inbound Email',
  enableMpWiseContract: 'Enable Market Preference Wise Contract',
  enableToUpdateEscrowParty: 'Enable To Update Escrow Party',
  enableToUploadDocumentRevision: 'Enable to Upload Document Revision',
  enteraContribution: 'Entera Contribution',
  enteraContributionAmount: 'Entera Contribution Amount',
  enteraCustomerId: 'Entera Customer ID',
  enteraMarketId: 'Entera Market Id',
  enteraOpportunitId: 'Entera Opportunity Id',
  escalationClauseAmount: 'Escalation Clause Amount',
  escrowAddress: 'Escrow Address',
  escrowCompany: 'Escrow Company',
  escrowCompanyAddress: 'Escrow Company Address',
  escrowCompanyEmail: 'Escrow Company Email Address',
  escrowCompanyName: 'Escrow Company Name',
  escrowCompanyContact: 'Escrow Company Contact',
  escrowCompanyPhone: 'Escrow Company Phone Number',
  escrowEmail: 'Escrow Email',
  escrowPhone: 'Escrow Phone',
  emailBody: 'Email Body',
  exclusion: 'Exclusions',
  entera: 'Entera',
  expire: 'Expire',
  from: 'From',
  extensionRequestReason: 'Extension Request Reason',
  file: 'File',
  fax: 'Fax',
  fileName: 'File Name',
  offerMakeOffer: 'offer_make_offer',
  forecastedCOE: 'Forecasted COE',
  forecastedCloseDate: 'Forecasted Close Date',
  forecastedDDEndDate: 'Forecasted DD End Date',
  gasProvider: 'Gas Provider',
  hasMunicipalInspections: 'Has Municipal Inspections',
  hasPostOccupancy: 'Has Post Occupancy',
  hoaAddendumReceived: 'HOA Addendum Received',
  hoaManagementCompanyName: 'Company Name',
  hoaManagementCompanyPhone: 'Company Phone',
  hoaManagementContactAddress: 'Contact Address',
  hoaManagementContactEmail: 'Contact Email',
  hoaManagementContactName: 'Contact Name',
  hoaManagementContactPhone: 'Contact Phone',
  homeOwnerAssociation: 'Homeowner Association',
  surveyRequested: 'Survey Requested',
  hvacAge: 'HVAC Age',
  id: 'ID',
  inclusion: 'Inclusions',
  inspection: 'Inspection',
  initialCommission: 'Initial Commission',
  initialCommissionPercentage: 'Initial Commission %',
  initialOfferPrice: 'Initial Offer Price',
  internalTerminationFeedback: 'Internal Termination Feedback',
  isInspectionContingenciesWaived: 'Inspection Contingency Waived?',
  isElectric: 'Is Electric',
  inactive: 'Inactive',
  isSelfManaged: 'Is Self Managed',
  isSeptic: 'Is Septic',
  insertVariable: 'Insert Variable',
  isWell: 'Is Well',
  lastVisitedAt: 'Last Visited At',
  lastActivatedAt: 'Last Activated At',
  lastDeactivatedAt: 'Last Deactivated At',
  latestRevison: 'Latest Revison',
  leaseArgumentReceived: 'Lease Agreement Received?',
  leaseDateExpiration: 'Lease Date Expiration',
  leaseEndDate: 'Lease End Date',
  leaseOwned: 'Lease Owned',
  leaseType: 'Lease Type',
  license: 'License',
  listAgentName: 'List Agent Name',
  listingAgentAddress: 'Listing Agent Address',
  listingAgentCompany: 'Listing Agent Company',
  listingAgentEmail: 'Listing Agent Email',
  listingAgentName: 'Listing Agent Name',
  listingAgentPhone: 'Listing Agent Phone',
  listPrice: 'List Price',
  lockboxCode: 'Lockbox Code',
  marginBottom: 'Margin Bottom',
  marginFooter: 'Margin Footer',
  marginHeader: 'Margin Header',
  marginLeft: 'Margin Left',
  marginRight: 'Margin Right',
  marginTop: 'Margin Top',
  header: 'Header',
  footer: 'Footer',
  market: 'Market',
  marketPreference: 'Market Preference',
  marketPreferences: 'Market Preferences',
  marketPreferenceName: 'Market Preference Name',
  maxOfferPrice: 'Max Offer Price',
  mls: 'MLS#',
  mlsCode: 'MLS Code',
  mlsStatus: 'MLS Status',
  mobile: 'Mobile',
  module: 'Module',
  moPhotosRequired: 'Mo Photos Required',
  msa: 'MSA',
  msaName: 'MSA Name',
  name: 'Name',
  new: 'New',
  queryResults: 'Query Results',
  name2: 'Name 2',
  offerDate: 'Offer Date',
  negotiatorRequestedRetradeAmount: 'Negotiator Requested Retrade Amount',
  negotiatorSentRetradeAmount: 'Negotiator Sent Retrade Amount',
  newConstructionNoTaxId: 'New Construction?',
  offerPackage: 'Offer Package',
  offerPrice: 'Offer Price',
  offerToDate: 'To Date',
  reTriggerIntegrationMessageOfferMade:
    'Re-trigger Integration Message - Offer Made',
  reTriggerIntegrationMessageOfferAccepted:
    'Re-trigger Integration Message - Offer Accepted',
  opportunity: 'Opportunity',
  opportunities: 'opportunities',
  opportunityStatus: 'State of Closing',
  opportunityBrokerageUsers: 'Opportunity Brokerage Users',
  optionAmount: 'Option Amount',
  optionDaysType: 'Option Days Type',
  optionFee: 'Option Fee',
  offers: 'Offers',

  optionFeeDeliveryDate: 'Option Fee Delivery Date',
  optionFeeVerified: 'Option Fee Verified',
  optionPeriod: 'Option Period',
  optionPeriodDays: 'Option Period Days',
  optionStatus: 'Option Status',
  originalStatus: 'Details(Action)',
  originalCloseOfEscrow: 'Original Close Of Escrow',
  otherCounterOfferTerms: 'Other Counter Offer Terms',
  primary: 'Primary',
  payoffAmount: 'Payoff Amount',
  preOffers: 'Pre Offers',
  preCloseNotification: 'Pre Close Notification',
  preCloseNotificationCcRecipient: 'Pre Close Notification CC Recipient',
  preCloseNotificationDays: 'Pre Close Notification Days',
  preCloseNotificationToRecipient: 'Pre Close Notification To Recipient',
  propertyAddress: 'Address',
  primaryUser: 'Primary',
  proposedPurchasePrice: 'Proposed Purchase Price',
  purchasePrice: 'Purchase Price',
  reductionType: 'Reduction Type',
  reductionType2: 'Reduction Type 2',
  rehabAmount: 'Rehab Amount',
  rejectDate: 'Reject Date',
  rejectNote: 'Reject Note',
  rejectReason: 'Reject Reason',
  relatedEvent: 'Related Event',
  replyTo: 'Reply To',
  reviewContract: 'Review Contract',
  removeMSA: 'Remove MSA',
  revision: 'Revision',
  revisions: 'Revisions',
  sellerName: 'Seller Name',
  rentalAmount: 'Rental Amount',
  receiptHandle: 'Receipt Handle',
  reportType: 'Report Type',
  requestingNameBuyer: 'Requesting Name(Buyer)',
  retradeReason: 'Retrade Reason',
  sellerSentReceived: 'Seller Sent/Received',
  sellerDisclosureReceived: 'Seller Disclosure Received',
  salePending: 'Sale Pending',
  sellerResponse: 'Seller Response',
  sewerProvider: 'Sewer Provider',
  state: 'State',
  status: 'Status',
  services: 'Services',
  sqsMessageID: 'Sqs Message Id',
  streetName: 'Street Name',
  subject: 'Subject',
  subType: 'Sub Type',
  taxID: 'Tax ID',
  terminationPending: 'Termination Pending',
  templateOrder: 'Template Order',
  tag: 'Tag',
  to: 'TO',
  terminationReasonSubcategory: 'Termination Reason Subcategory',
  trashProvider: 'Trash Provider',
  transactionHistories: 'Transaction Histories',
  transactionHistory: 'Transaction History',
  transactionDetail: 'Transaction Detail',
  type: 'Type',
  useJacksonvilleNefarContract: 'Use Jacksonville Nefar Contract',
  vendor: 'Vendor',
  waterProvider: 'Water(or well) Provider',
  website: 'Website',
  whitelistEmailDomainsSeperatedByCommna:
    'Whitelist Email Domains(Seperated by comma)',
  closeToDate: 'Close To Date',
  nextSellerContactDate: 'Next Seller Contact Date',
  reasonDescription: 'Reason Description',
  notes: 'Notes',
  offerGrade: 'Offer Grade',
  lowestAcceptablePrice: 'Lowest Acceptable Price',
  user: 'User',
  event: 'Event',
  queue: 'Queue',
  attampts: 'Attampts',
  messageId: 'Sqs Message Id',
  messageOrigin: 'Message Origin',
  dateSent: 'Date Sent',
  message: 'Message',
  dateCreated: 'Date Created',
  documentStatus: 'Document Status',
  serviceType: 'Service Type',
  inspectionDate: 'Inspection Date',
  requestInfo: 'Request Info',
  requestedBy: 'Requested By',
  requestedDate: 'Requested Date',
  inspectionScheduled: 'Inspection Scheduled',
  invalidFileType: 'Invalid file type',
  allowedFileTypes: 'Allowed file types',
  inspectionConfirmationBy: 'Inspection Confirmation By',
  inspectionConfirmationDate: 'Inspection Confirmation Date',
  requestDate: 'Request Date',
  requestBy: 'Request By',
  closingNotes: 'Closing Notes',
  closingDiligencePeriod: 'closing_diligence_period',
  inspectionNotes: 'Inspection Notes',
  viewPdfTemplate: 'View Pdf Template',
  editPdfTemplate: 'Edit Pdf Template',
  addPdfTemplate: 'Add Pdf Template',
  removePdfTemplate: 'Remove Pdf Template',
  pdfTemplates: 'PDF Templates',
  terminateUser: 'Terminate User',
  replace: 'Replace',
  replaceUser: 'Replace User',
  replaceBrokerageUser: 'Replace Brokerage User',
  viewer: 'Viewer',
  viewMarketPreferenceBrokerageUser: 'View Market Preference Brokerage User',
  viewEmailTemplate: 'View Email Template',
  viewAsPdf: 'View as PDF',
  viewAsReadablePdf: 'View as Readable PDF',
  editEmailTemplate: 'Edit Email Template',
  addEmailTemplate: 'Add Email Template',
  viewDocument: 'View Document',
  editDocument: 'Edit Document',
  removeDocument: 'Remove Document',
  deactive: 'Deactive',
  closingName: 'Closing Name',
  enteraPropertyId: 'Entera Property ID',
  enteraOpportunityId: 'Entera Opportunity ID',
  enteraOpportunityUrl: 'Entera Opportunity URL',
  titleApplicationUrl: 'Title Application URL',
  propertyType: 'Property Type',
  listingType: 'Listing Type',
  listDate: 'List Date',
  mlsUpdatedDate: 'MLS Updated Date',
  enteraSourcedOffMarket: 'Entera Sourced Off-Market',
  listingSource: 'Listing Source',
  dataSource: 'Data Source',
  listPriceUpdatedDate: 'List Price Updated Date',
  offerStrategy: 'Offer Strategy',
  offerInitialized: 'Offer Initialized',
  offerFinalized: 'Offer Finalized',
  submittingUser: 'Submitting User',
  behalfOfSubmittingUser: 'Behalf of Submitting User',
  buyerAssignability: 'Buyer Assignability',
  revisedListPrice: 'Revised List Price',
  offerToMarketValue: 'Offer to Market Value %',
  offerToList: 'Offer to List %',

  mogReceived: 'Mog Received',
  rpdReceived: 'Rpd Received',
  addendumNo: 'Addendum No',

  publicRemarksC: 'Public Remarks',
  realtorRemarksC: 'Realtor Remarks',
  additionalTermsCommentsC: 'Terms/Comments',
  backwardsStatusMoveReason: 'Non-standard status move reason',
  offerResponseDetails: 'Offer Response Details',
  legalDescription: 'Legal Description',
  subDivision: 'Subdivision',
  subdivisionSectionC: 'Subdivision Section',
  landLotsC: 'Land Lots(s)',
  unitNumber: 'Unit Number',
  propertyLotNumber: 'Lot Number',
  block: 'Block',
  phaseNumber: 'Phase Number',
  districtNumber: 'District Number',
  deedBookNumber: 'Deed Book Number',
  platPageNumber: 'Plat Page Number',
  floodZone: 'Flood Zone',
  yearBuilt: 'Year Built',
  pool: 'Pool',
  sqFt: 'Sq Ft',
  waterAndSewer: 'Water and Sewer',
  bath: 'Bath',
  foundationType: 'Foundation Type',
  bed: 'Bed',
  garage: 'Garage',
  lotSize: 'Lot Size',
  basement: 'Basement',
  equipment: 'Equipment',
  roofType: 'Roof Type',
  heatingSystem: 'Heating System',
  coolingSystem: 'Cooling System',
  soldPrice: 'Sold Price',
  optionalAmount: 'Optional Amount',
  additionalEarnestMoney: 'Additional Earnest Money',
  monthlyHOAFees: 'Monthly HOA Fees',
  sellerClosingCosts: 'Seller Closing Costs',
  sellerClosingSupport: 'Seller Closing Support $',
  financing: 'Financing',
  financingAmount: 'Financing $$',
  typeOfFinancing: 'Type Of Financing',
  initialInterestRate: 'Interest Rate %',
  loanPercentage: 'Loan %',
  appraisalRequired: 'Appraisal Required',
  initialFinancialTermYears: 'Financing Term (Yrs)',
  rentAVM: 'Rent AVM',
  rentAVMActual: 'Rent AVM Actual',
  saleAVM: 'Sale AVM',
  saleAVMActual: 'Sale AVM Actual',
  opportunityRehab: 'Opportunity Rehab $',
  actualRehab: 'Actual Rehab',
  calculatedTax: 'Calculated Tax',
  buyerSentRetradeAmount: 'Buyer Requested Retrade Amount',
  buyerContractName2: 'Buyer Contract Name 2',
  buyerNameHud: 'Buyer Name Prelim HUD',
  buyerContractNameAtOfferSent: 'Buyer Contract Name at Offer Sent',
  buyerContractNameAtDiligenceStart: 'Buyer Contract Name at Diligence Start',
  buyerContractNameAtClosing: 'Buyer Contract Name at Closing',
  city: 'City',
  zip: 'Zip',
  country: 'County',
  copied: 'Copied!',
  copiedToCliboard: 'Copy to Clipboard',
  customerReservationStatus: 'Customer Reservation Status',
  schoolDistrict: 'School District',
  customerAcceptedStatusVerified: 'Customer Accepted Status Verified',
  fullAddress: 'Full Address',
  offerSubmitType: 'Offer Submit Type',
  offerSubmitByDate: 'Offer Submit By Date',
  offerExpiration: 'Offer Expiration',
  sellerOwnershipRepresentation: 'Seller Ownership Representation',
  earnestMoneyDueType: 'Earnest Money Due Type',
  initialDueDiligenceEnd: 'Initial Due Diligence End',
  noDueDiligencePeriod: 'No Due Diligence Period',
  forecastedDdEndDate: 'Forecasted DD End Date',
  shortSaleSellerAcceptDate: 'Short Sale Seller Accept Date',
  originalCloseOfEscrowDate: 'Original Close of Escrow Date',
  terminationReasons: 'Termination Reasons',
  optionInspectionPeriod: 'Option Inspection Period',
  otherCancellationReason: 'Other Cancellation Reason',
  offerLostDate: 'Offer Lost Date',
  howManyCompetingOffers: 'How many competing offers?',
  offerCancelledDate: 'Offer Cancelled Date',
  highestCompetingOfferPrice: 'Highest competing offer price',
  bidId: 'Bid ID',
  bidUrl: 'Bid URL',
  portfolioId: 'Portfolio ID',
  portfolioName: 'Portfolio Name',
  emailUniqueId: 'Email Unique ID',
  productType: 'Product Type',
  leaseBackAllowed: 'Lease Back Allowed',
  warrantyRequired: 'Warranty Required',
  leasingRestrictions: 'Leasing Restrictions',
  restrictionsNotes: 'Restrictions Notes',
  disableInspectionEmailsWithWaived:
    'Disable Inspection Emails with Waived Inspection?',
  buyerOfferKey: 'Buyer Offer Key',
  occupancyStatus: 'Occupancy Status',
  showingInstructions: 'Showing Instructions',
  accessNotes: 'Access Notes',
  pending: 'pending',
  integration: 'integration',
  pendingExtension: 'Pending Extension',
  pendingRetrade: 'Pending Retrade',
  accessRequestedDate: 'Access Requested Date',
  accessRequestedBy: 'Access Requested By',
  documentDelivered: 'Document Delivered',
  keyLocation: 'Key Location',
  emStatus: 'Earnest Money Status',
  mailboxLocation: 'Mailbox Location',
  gateCode: 'Gate Code',
  optionFeeStatus: 'Option Fee Status',
  emdDeliveryDate: 'EMD Delivery Date',
  congratsLetterSentDate: 'Congrats Letter Sent Date',
  congratsLetterSentBy: 'Congrats Letter Sent By',
  moPhotosSource: 'M/O Photos Source',
  moPhotosDate: 'M/O Photos Date',
  moPhotosLink: 'M/O Photos Link',
  roofAge: 'Roof Age',
  municipalInspectionTypes: 'Municipal Inspection Types',
  hoaUrl: 'HOA URL',
  municipalInspectionNotes: 'Municipal Inspection Notes',
  approvedToClose: 'Approved to Close?',
  listBrokerName: 'List Broker Name',
  listBrokerAddress: 'List Broker Address',
  listAgentEmail: 'List Agent Email',
  listAgentPhone: 'List Agent Phone',
  escrowPercent: 'Escrow Percent',
  listingAgentLicense: 'Listing Agent License',
  closingAttorney: 'Closing Attorney',
  licensedSupervisorOfListingAssociate:
    'Licensed Supervisor of Listing Associate',
  closingAttorneyContact: 'Closing Attorney Contact',
  licensedSupervisorLicenseNumber: 'Licensed Supervisor License Number',
  closingAttorneyEmail: 'Closing Attorney Email',
  closingAttorneyPhone: 'Closing Attorney Phone',
  closingAttorneyAddress: 'Closing Attorney Address',
  coListAgentEmail: 'Co List Agent Email',
  coListAgentPhone: 'Co List Agent Phone',
  transactionCoordinatorEmail: 'Transaction Coordinator Email',
  sellerName2: 'Seller Name 2',
  sellerEmail: 'Seller Email',
  sellerPhone: 'Seller Phone',
  sellerNoticeAddress: 'Seller Notice Address',
  buyerNoticeAddress: 'Buyer Notice Address',
  buyerEmail: 'Buyer Email',
  buyerPhone: 'Buyer Phone',
  buyerAttorney: 'Buyer Attorney',
  photo: 'Photo',
  marketSignerName: 'Market Signer Name',
  marketSignerInitials: 'Market Signer Initials',
  marketSignerName2: 'Market Signer Name 2',
  marketSignerInitials2: 'Market Signer Initials 2',
  repName: 'Rep Name',
  repEmail: 'Rep Email',
  repCompany: 'Rep Company',
  repPhone: 'Rep Phone',
  propertyAccessContactName: 'Property Access Contact Name',
  propertyAccessContactPhone: 'Property Access Contact Phone',
  propertyAccessContactEmail: 'Property Access Contact Email',
  propertyAccessComments: 'Property Access Comments',
  customerBrokerage: 'Customer Brokerage',
  customerBrokerName: 'Customer Broker Name',
  primaryNegotiator: 'Primary Negotiator',
  negotiatorAtOfferSent: 'Negotiator At Offer Sent',
  negotiatorAtOfferAcceptance: 'Negotiator At Offer Acceptance',
  negotiatorAtClosing: 'Negotiator At Closing',
  benefittingNegotiatorChangedAt: 'Benefitting Negotiator Changed At',
  benefittingNegotiatorChangedBy: 'Benefitting Negotiator Changed By',
  benefittingNegotiatorIsLocked: 'Benefitting Negotiator Is Locked',
  primaryTc: 'Primary TC',
  primaryLogistics: 'Primary Logistics',
  primaryTcAtDiligencePeriod: 'Primary TC at Diligence Period',
  primaryLogisticsAtDiligencePeriod: 'Primary Logistics at Diligence Period',
  primaryTcAtSalePending: 'Primary TC at Sale Pending',
  primaryLogisticsAtSalePending: 'Primary Logistics at Sale Pending',
  primaryTcAtClosing: 'Primary TC at Closing',
  primaryLogisticsAtClosing: 'Primary Logistics at Closing',
  executedContractEmailTo: 'Executed Contract Email To',
  executedContractEmailCc: 'Executed Contract Email Cc',
  executedContractEmailBcc: 'Executed Contract Email Bcc',
  escrowContact: 'Escrow Contact',
  listingAgent: 'Listing Agent',
  coListAgent: 'Co List Agent',
  transactionCoordinatorName: 'Transaction Coordinator Name',
  offerSubmittedBy: 'Offer Submitted By',
  saveForSignatureBy: 'Save For Signature By',
  benefittingNegotiator: 'Benefitting Negotiator',
  wireStatus: 'Wire Status',
  wireFeeAmount: 'Wire Fee Amount',
  earnestInstructions: 'Earnest Instructions',
  customerName: 'Customer Name',
  optionDelivery: 'Option Delivery',
  optionDeliveryDate: 'Option Delivery Date',
  titlePayor: 'Title Payor',
  settlementDate: 'Settlement Date',
  probability: 'Probability',
  buyerBonus: 'Buyer Bonus',
  commissionPayType: 'Commission Pay Type',
  offerPriceAtAcceptance: 'Offer Price At Acceptance',
  commissionContributionAtOfferAcceptance:
    'Commission Contribution at Offer Acceptance',
  contractReviewComplete: 'Contract Review Complete',
  commissionPending: 'Commission Pending',
  pendingCommission: 'Pending Commission',
  commissionReduction: 'Commission Reduction',
  totalReductions: 'Total Reductions',
  specialInstructions: 'Special Instructions',
  commissionReceived: 'Commission Received',
  hudCommission: 'Hud Commission',
  actualCommission: 'Actual Commission',
  actualCommissionPercentage: 'Actual Commission Percentage',
  leaseback3DayReminderSentBy: 'Leaseback 3-day Reminder Sent By',
  leaseback3DayReminderSentAt: 'Leaseback 3-day Reminder Sent At',
  leaseback1DayReminderSentBy: 'Leaseback 1-day Reminder Sent By',
  leaseback1DayReminderSentAt: 'Leaseback 1-day Reminder Sent At',
  solarPanelLeaseDateExpiration: 'Solar Panel Lease Date Expiration',
  CsaAmount2: 'CDA $ Amount 2',
  CdaPayTo2: 'CDA Pay To 2',
  CsaAmount3: 'CDA $ Amount 3',
  CdaPayTo3: 'CDA Pay To 3',
  QbInvoiceNumber: 'QB Invoice Number',
  contractAmendmentDate: 'Contract Amendment Date',
  sellerType: 'Seller Type',
  sellerRepresentativeName: 'Seller Representative Name',
  sellerRepresentativeEmail: 'Seller Representative Email',
  buyerSource: 'Buyer Source',
  sellerRepresentativeCompany: 'Seller Representative Company',
  didYouRepresenttheBuyer: 'Did You Represent the Buyer',
  sellerRepresentativeType: 'Seller Representative Type',
  sellerSource: 'Seller Source',
  leadSourceName: 'Lead Source Name',
  doesTheSellerHaveOutSideRepresentation:
    'Does the Seller have outside Representation?',
  leadSourceEmail: 'Lead Source Email',
  leadSourceType: 'Lead Source Type',
  leadOwnerName: 'Lead Owner Name',
  leadOwnerEmail: 'Lead Owner Email',
  leadOwnerType: 'Lead Owner Type',
  buyerRepresentativeName: 'Buyer Representative Name',
  buyerRepresentativeEmail: 'Buyer Representative Email',
  buyerRepresentativeType: 'Buyer Representative Type',
  negotiatorTakeOfCommission: 'Negotiator Take of Commission',
  yourCommission: 'Your Commission $',
  intendedBuyerName: 'Intended Buyer Name',
  intendedBuyerEmail: 'Intended Buyer Email',
  intendedBuyerType: 'Intended Buyer Type',
  uploadedByName: 'Uploaded By Name',
  uploadedByEmail: 'Uploaded By Email',
  uploadedByType: 'Uploaded By Type',
  partiesDetails: 'Parties Details',
  offerDetails: 'Offer Details',
  commissionInput: 'Commission Input',
  commissionOutput: 'Commission Output',
  help: 'Help',
  tenantName: 'Tenant Name',
  tenantPhoneNumber: 'Tenant Phone Number',
  tenantEmailAddress: 'Tenant Email Address',
  phone: 'Phone',
  hoaName: 'HOA Name',
  createNewServiceProvider: 'Create new service provider',
  feeAdvance: 'Fee Advance',
  expense: 'Expense',
  hoaManagementCompany: 'HOA Management Company',
  tenant: 'Tenant',
  utilityProvider: 'Utility Provider',
  negotiationNotes: 'Negotiation Notes',
  transactionNotes: 'Transaction Notes',
  add: 'Add',
  emailAddress: 'Email Address',
  password: 'Password',
  login: 'Login',
  stateOfClosing: 'State of Closing',
  template: 'Template',
  termination: 'Termination',
  field: 'Field',
  oldValue: 'Old Value',
  newValue: 'New Value',
  createdBy: 'Created By',
  changeDate: 'Change Date',

  removeMsa: 'Remove Msa',
  assoicateMsa: 'Associate Msa',

  myReport: 'My Report',
  myReports: 'My Reports',
  makeOffer: 'Make Offer',
  edit: 'Edit',
  createBrokerageUser: 'Create Brokerage User',
  contracts: 'Contracts',
  selectContracts: 'Select Contracts',
  emailTemplates: 'Email Templates',
  selectEmailTemplates: 'Select Email Templates',
  documents: 'Documents',
  selectDocuments: 'Select Documents',
  createDocument: 'Create Document',
  uploadDocuments: 'Upload Documents',
  home: 'Home',
  mpBrokerageUsers: 'Market Preference Brokerage Users',
  sqs: 'SQS',
  createMpBrokerageUsers: 'Create Market Preference Brokerage User',
  createContract: 'Create Contract',
  editContract: 'Edit Contract',
  createEmailTemplate: 'Create Email Templates',
  myPhoneNumber: 'My Phone Number',
  initiatePhoneCall: 'Initiate Phone Call',
  offersClosings: 'Closings',
  dashboard: 'Dashboard',
  negotiator: 'Agent',
  closing: 'Closing',
  transaction: 'Transaction',
  acceptOffer: 'Accept Offer',
  buyerCounterOfferConfirmation: 'Buyer Counter Offer Confirmation',
  cancelOffer: 'Cancel Offer',
  clearDueDiligence: 'Clear Due Diligence',
  clientChat: 'Client Chat',
  counterOfferReceipt: 'Counter Offer Receipt',
  dueDiligenceFees: 'Due Diligence Fees',
  enterDueDiligence: 'Enter Due Diligence',
  extensionConfirmation: 'Extension Confirmation',
  extensionRequest: 'Extension Request',
  negotiatorNotes: 'Negotiator Notes',
  optionEarnestFeeStatus: 'Option Earnest Fee Status',
  rejectOffer: 'Reject Offer',
  retradeComplete: 'Retrade Complete',
  retradeRequest: 'Retrade Request',
  terminationApproval: 'Termination Approval',
  terminationRequest: 'Termination Request',
  all: 'All',
  properties: 'Properties',
  logout: 'Logout',
  profile: 'Profile',
  profileId: 'Profile Id',
  selectedNumberOfLots: 'Selected Number Of Lots',
  removeEmailTemplate: 'Remove Email Template',
  deleteEmailTemplate: 'Delete Email Template',
  searchQuery: 'Search Query',
  reports: 'Reports',
  upload: 'Upload',
  uploadDocument: 'Upload Document',
  view: 'View',
  sqsMessage: 'SQS Messages',
  calls: 'Calls',
  task: 'Task',
  tasks: 'Tasks',
  generateContract: 'Generate Contracts',
  viewChangelog: 'View Changelog',
  viewChangelogs: 'View Changelogs',
  compose: 'Compose',
  inbox: 'Inbox',
  draft: 'Draft',
  parties: 'Parties',
  brokerageNotes: 'Brokerage Notes',
  dashboards: 'Dashboards',
  msaList: 'Msa List',
  customerAccounts: 'Customer Accounts',
  msaSelectionList: 'Msa Selection List',
  submit: 'Submit',
  userName: 'Username',
  confirmPassword: 'Confirm Password',
  opportunityName: 'Opportunity Name',
  saleDate: 'Sale Date',
  firstName: 'First Name',
  lastName: 'Last Name',
  userType: 'UserType',
  employeeStatus: 'Employee Status',
  addressStreet: 'Address Street',
  addressCity: 'Address City',
  addressStateRegion: 'Address State/Region',
  addressPostalCode: 'Address Postal Code',
  addressCountry: 'Address Country',
  timezone: 'Time Zone',
  inspectionContingencyWaived: 'Inspection Contingency Waived?',
  recentViewed: 'Recent Viewed',
  inclusions: 'Inclusions',
  exclusions: 'Exclusions',
  title: 'Title',
  knowledgeBase: 'Knowledge Base',
  deedBookPage: 'Deed Book Page',
  platBookNumber: 'Plat Book Number',
  workPhone: 'Work Phone',
  offerFinalizedAt: 'Offer Finalized At',
  areYouSure: 'Are you sure',
  publishDate: 'Publish Date',
  deleteService: 'Delete Service',
  serviceDeleteSuccess: 'Service deleted successfully!',
  removeMarketPreference: 'Remove Market Preference',
  viewMarketOfferDefaults: 'View Market Offer Defaults',
  removeEmail: 'Remove Email',
  areYouSureYouWantToRemove: 'Are you sure you want to remove',
  areYouSureYouWantToDelete: 'Are you sure you want to delete',
  body: 'Body',
  querySearch: 'Query Search',
  inspectionDateIsEmpty: 'Inspection date is empty. Proceed anyway?',
  endDateOfDueDiligenceIsEmpty:
    'End date of Due diligence is empty. Proceed anyway?',
  inspectionDateAfterScheduledEndOfDueDiligence:
    'The inspection date you are scheduling will happen AFTER the scheduled end of Due Diligence. Proceed anyway?',
  auditFields: 'Audit Fields',
  changesWillDiscarded: 'Your changes will be discarded. Do you want proceed ?',
  youAreAttemptingCounterOfferConfirmationBeforeReceipt:
    'You are attempting to confirm a counteroffer that was never recorded. Please start with the Counteroffer Receipt action and then try again.',
  vpnErrorMessage: 'Unable to authenticate, please check your user settings.',
  forward: 'Forward',
  opportunitySaved: 'Oppoertunity Saved',
  noteAdded: 'Note Added',
  terminate: 'Terminate',
  select: 'Select',
  list: 'List',
  marketPreferencesBrokerageUser: 'Market Preferences Brokerage User',
  marketPreferencesBrokerageUsers: 'Market Preference Brokerage Users',
  clickHereDownloadManually: 'Click here to download manually',
  ifYourDownloadDoesNotStartAfterSeconds:
    'If your download does not start after 30 seconds',
  youAreOffline: 'You Are Offline',
  replyAll: 'Reply All',
  sameTypeAndSubTypeNotAllowed:
    'Same Document Type and Document Subtype not allowed to upload',
  marketPreferenceNotAssociated:
    'Do not find any contracts because opportunity do not have associated market preferences.',
  templateType: 'Template Type',
  emailTemplateDeleteFailure:
    'This email template has been associated with market preference, So cannot delete it.',
  emailTemplateDeleteSuccess: 'Email-Template deleted successfully!',
  delete: 'Delete',
  hudCommissionFormula: `Hud Commission= Actual Commission -
(if(reduction type = HUD Concession, commission_reduction, 0) - (if(reduction type_2 = HUD Concession, commission_reduction_2, 0) - (if(reduction type_3 = HUD Concession, commission_reduction_3, 0))))`,
  finalCommissionFormula: `Actual Commission= (Initial Commission - Total Reductions)`,
  initialcommissionAmountFormula: `Initial Commission = (Initial Commission % * (Purchase Price or Contract Price or Initial Offer Price) / 100) + Buyer Bonus`,
  initialcommissionAmountPercentageFormula: `Initial Commission % = (Initial Commission / (Purchase Price or Contract Price or Initial Offer Price)) * 100`,
  EnterName: 'Enter Name',
  deleteContract: 'Delete Contract',
  contractDeleteSuccess: 'Contract deleted successfully!',
  contarctDeleteFailure:
    'This contract has been associated with market preference, So cannot delete it.',
  terminationComplete: 'Termination Complete',
  brokerageAction: 'Brokerage Actions',
  templateName: 'Template Name',
  crmDocuments: 'CRM Documents',
  localDocuments: 'Local Documents',
  portfolios: 'Portfolios',
  groupingNamesAndIds: 'Grouping Names and Grouping Ids',
  realEstateCoordinator: 'Real Estate Coordinator',
  offerBlockedReason: 'Offer - Blocked Reason',
  offerBlockedReasonAt: 'Offer - Blocked Reason At',
  isOfferBlockedResolved: 'Is Offer Blocked Resolved?',
  offerBlockedResolvedAt: 'Offer - Blocked Resolved At',
  offerBlockedResolvedBy: 'Offer - Blocked Resolved By',
  documentTag: 'Document Tag',
  shouldAttachPSAWithHOADocumentInCashOfferEmail:
    'Should Attach PSA With HOA Document in Cash Offer Email?',
  roles: 'Roles',
  role: 'Role',
  removeRole: 'Remove Role',
  daysAfterOfferExpires: 'Days after offer expires'
};

export default fieldLabel;
